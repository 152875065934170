<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="top">
            <img class="left" :src="goodsDetails.picUrl" alt="" />
            <div class="right">
                <div>
                    <div class="title">{{ goodsDetails.name }}</div>
                    <div class="duihuan">已兑换{{ goodsDetails.exchangeCount }}件</div>
                    <div class="jifen">{{ goodsDetails.exchangeScore }}积分</div>
                    <div class="duihuanDetail">
                        <div class="title1">兑换详情</div>
                        <span>{{ goodsDetails.exchangeScore }}积分</span>
                    </div>
                    <div class="peisong">
                        <div class="title1">配送方式</div>
                        <el-input v-model="number" disabled placeholder="数字码" placeholderClass="placeholderClass"></el-input>
                    </div>
                    <div class="shuliang">
                        <div class="title1">数量</div>
                        <div>
                            <i @click="goodsDetails.num--" class="el-icon-minus"></i>
                            <el-input v-model="goodsDetails.num" placeholder="数量" placeholderClass="placeholderClass"></el-input>
                            <i @click="goodsDetails.num++" class="el-icon-plus"></i>
                        </div>
                    </div>
                    <div class="zhuyi">
                        <img src="@/assets/images/ExclamationCircle.png" alt="" />
                        <span>注：非实物物品兑换完成后，直接在平台上查看兑换券码。实物商品，需填写收货信息进行邮寄。</span>
                    </div>
                </div>
                <div class="butt" @click="$router.push({ name: 'bankExchange', query: { goodsDetails: JSON.stringify(goodsDetails) } })">立即兑换</div>
            </div>
        </div>
        <div class="titles">兑换规则</div>
        <div class="details">{{ goodsDetails.exchangeRule }}</div>
        <div class="titles">商品详情</div>
        <div style='white-space: pre-wrap;' class="details" v-html="goodsDetails.goodsDetail ? goodsDetails.goodsDetail.replace(/<img/g, '<img style=width:100%;height:auto; ') : ''"></div>
    </div>
</template>

<script>
import { getGoodsDetails } from '@/api';
export default {
    name: 'OrderDetail',
    data() {
        return {
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '文化银行',
                    to: { path: '/bank', query: { tabs: '0' } },
                },
                // {
                //     title: '新品推荐',
                //     to: { path: '/bankList', query: { types: '5' } },
                // },
                {
                    title: '商品详情',
                },
            ],
            number: '',
            num: 1,
            goodsDetails: {},
        };
    },
    watch: {
        num(val) {
            if (val < 1) {
                this.num = 1;
            }
        },
    },
    created() {
        let typeArr = ['我能兑换', '折扣专区', '新品推荐'];
        // this.types = this.$route.query.types;
        // this.nav[2].title = typeArr[this.$route.query.types];
        // this.nav[2].to.query.types = this.$route.query.types;
        this.getGoodsDetails();
    },
    methods: {
        numAdd() {
            this.num++;
        },
        numCat() {
            if (this.num > 1) {
                this.num--;
            }
        },
        getGoodsDetails() {
            getGoodsDetails(this.$route.query.id).then((res) => {
                if (res.code === 200) {
                    res.data.num = 1;
                    this.goodsDetails = res.data;
                }
            });
        },
    },
};
</script>

<style scoped lang="less">
.top {
    display: flex;

    .left {
        width: 450px;
        height: 450px;
        border-radius: 10px;
    }

    .right {
        flex: 1;
        margin-left: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
            margin-bottom: 12px;
            font-weight: 700;
            font-size: 20px;
            line-height: 29px;
            color: #333333;
            height: 60px;
            .ellipsisLine(2);
        }
        .duihuan {
            font-size: 14px;
            line-height: 20px;
            color: #999999;
        }

        .jifen {
            font-weight: 700;
            font-size: 20px;
            line-height: 29px;
            color: #eda200;
            margin-top: 24px;
        }

        .duihuanDetail {
            display: flex;
            flex-direction: column;
            margin-top: 32px;
            padding-bottom: 8px;
            border-bottom: 1px solid #ededed;
            width: 375px;
            .title1 {
                font-weight: 700;
                font-size: 14px;
                color: #333333;
            }
            span {
                font-size: 12px;
                line-height: 17px;
                color: #eda200;
                padding: 4px 8px;
                background: rgba(237, 162, 0, 0.1);
                border-radius: 2px;
                margin-top: 6px;
                width: max-content;
            }
        }
        .peisong {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 12px;
            padding-bottom: 12px;
            border-bottom: 1px solid #ededed;
            width: 375px;
            .title1 {
                font-weight: 700;
                font-size: 14px;
                color: #333333;
            }
            .el-input,
            .el-input__inner {
                border: 0;
                background: none;
                font-size: 14px;
                line-height: 20px;
                color: #999999;
                width: 200px;
                text-align: right;
            }
            ::v-deep .el-input__inner {
                border: 0;
                background: none;
                font-size: 14px;
                line-height: 20px;
                color: #999999;
                width: 200px;
                text-align: right;
            }
            ::v-deep .placeholderClass {
                font-size: 14px;
                line-height: 20px;
                color: #999999;
                text-align: right;
            }
        }
        .shuliang {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 12px;
            padding-bottom: 12px;
            border-bottom: 1px solid #ededed;
            width: 375px;
            .title1 {
                font-weight: 700;
                font-size: 14px;
                color: #333333;
            }
            div {
                .el-input,
                .el-input__inner {
                    border: 0;
                    background: none;
                    font-size: 14px;
                    line-height: 20px;
                    color: #999999;
                    width: 100px;
                    text-align: right;
                }
                ::v-deep .el-input__inner {
                    border: 0;
                    background: none;
                    font-size: 14px;
                    line-height: 20px;
                    width: 100px;
                    text-align: center;
                }
                ::v-deep .placeholderClass {
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                }
                i {
                    font-size: 10px;
                    color: #999999;
                    cursor: pointer;
                    background: rgba(0, 0, 0, 0.1);
                    border-color: rgba(0, 0, 0, 0.1);
                    width: 20px;
                    height: 20px;
                    text-align: center;
                    line-height: 20px;
                    border-radius: 100%;
                    font-weight: 700;
                }
                i:last-child {
                    background: #eda200;
                    color: white;
                }
            }
        }
        .zhuyi {
            display: flex;
            margin-top: 11px;
            img {
                width: 16px;
                height: 16px;
                margin-right: 3px;
                margin-top: 2px;
            }
            span {
                font-size: 12px;
                line-height: 17px;
                color: #999999;
                width: 356px;
                .ellipsisLine(2);
            }
        }

        .butt {
            width: 100%;
            height: 48px;
            background: #eda200;
            border-radius: 4px;
            font-weight: 700;
            font-size: 16px;
            color: #ffffff;
            text-align: center;
            line-height: 48px;
        }

        .item {
            display: flex;
            align-items: center;
            margin-top: 16px;

            img {
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }

            span {
                font-size: 16px;
                color: #333333;
                flex: none;
                order: 1;
                flex-grow: 0;
            }
        }

        .detail {
            display: flex;
            flex-direction: column;
            margin-top: 16px;
            padding: 16px 132px 16px 16px;
            background: white;
            border: 1px dashed #eda200;
            width: max-content;

            span {
                font-size: 15px;
                line-height: 22px;
                color: #333333;
            }
            span:last-child {
                margin-top: 12px;
            }
        }

        .butt {
            padding: 12px 110px;
            background: #eda200;
            box-shadow: 0 2px 4px rgba(14, 79, 71, 0.2);
            border-radius: 4px;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #ffffff;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin-top: 37px;
            width: max-content;
        }
    }
}

.titles {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #333333;
    margin: 24px 0 12px 0;
}
.details {
    font-size: 16px;
    line-height: 23px;
    color: #666666;
}
.details:last-child {
    margin-bottom: 150px;
}
</style>
